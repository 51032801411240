// Base For development
// export const Base = `https://medical.mobitplus.com/`;

// Base for production main
export const Base = `https://apis.healthpackpharmacy.com/`;

const CURLPath = {
    json: {
        'Content-Type': 'application/json'
    },
    form: {
        'Content-Type': 'multipart/form-data'
    },
    'admin/login': 'admin/login',
    'admin/forgotpassword': 'admin/forgotpassword',
    'admin/resetpassword': 'admin/resetpassword',
    'admin/logout': 'admin/logout',

    'admin/sidebar_counts': 'admin/sidebar_counts',

    //profile
    'admin/profile': 'admin/profile',
    'admin/profile_update': 'admin/profile_update',
    'admin/change_picture': 'admin/change_picture',
    'admin/changepassword': 'admin/changepassword',
    //role
    'admin/role': 'admin/role',
    'admin/role/isactive': 'admin/role/isactive',

    //gender
    'admin/gender': 'admin/gender',

    //user
    'admin/users': 'admin/users',
    'admin/users_list': 'admin/users_list',
    'admin/orders/user_list': 'admin/orders/user_list',
    'admin/users/isactive': 'admin/users/isactive',
    //subadmin
    'admin/subadmin_list': 'admin/subadmin_list',
    'admin/subadmin': 'admin/subadmin',
    'admin/subadmin/isactive': 'admin/subadmin/isactive',
    'admin/subadmin/group': 'admin/subadmin/group',
    //driver
    'admin/driver': 'admin/driver',
    'admin/driver_list': 'admin/driver_list',
    'admin/driver/isactive': 'admin/driver/isactive',
    //dynamic slider
    'admin/slider_list': 'admin/slider_list',
    'admin/slider': 'admin/slider',
    'admin/slider/isactive': 'admin/slider/isactive',
    'admin/slider/update': 'admin/slider/update',
    //subject
    'admin/subject': 'admin/subject',
    'admin/subject/isactive': 'admin/subject/isactive',
    'admin/subject_list': 'admin/subject_list',
    //dynamic banner
    'admin/banner_list': 'admin/banner_list',
    'admin/banner': 'admin/banner',
    'admin/banner/isactive': 'admin/banner/isactive',
    'admin/banner/update': 'admin/banner/update',
    //category
    'admin/category_list': 'admin/category_list',
    'admin/category': 'admin/category',
    'admin/categories/all': 'admin/categories/all',
    'admin/categories': 'admin/categories',
    'admin/category/update': 'admin/category/update',
    'admin/category/isactive': 'admin/category/isactive',
    //sucategory
    'admin/subcategory_list': 'admin/subcategory_list',
    subcategory: 'subcategory',
    'admin/subcategory': 'admin/subcategory',
    'admin/subcategory/update': 'admin/subcategory/update',
    'admin/subcategory/isactive': 'admin/subcategory/isactive',
    //maintance mode
    'admin/maintanance/update': 'admin/maintanance/update',
    'admin/maintanance': 'admin/maintanance',

    //dynamic pages
    'admin/pages_list': 'admin/pages_list',
    'admin/pages': 'admin/pages',
    //company
    'admin/company_list': 'admin/company_list',
    'admin/company': 'admin/company',
    'admin/company/isactive': 'admin/company/isactive',
    'admin/companylist': 'admin/companylist',
    'admin/mapping/company/unmapped': 'admin/mapping/company/unmapped',
    //contact us
    'admin/contactUs': 'admin/contactUs',
    'admin/contactUs_list': 'admin/contactUs_list',
    // CK-Editor
    'admin/upload_image': 'admin/upload_image',

    //city
    'admin/city_list': 'admin/city_list',
    'admin/city': 'admin/city',
    'admin/city/isactive': 'admin/city/isactive',
    //credential
    'admin/credencials_list': 'admin/credencials_list',
    'admin/credencials': 'admin/credencials',
    'admin/credencial/upload': 'admin/credencial/upload',
    //product
    'admin/product': 'admin/product',
    'admin/product/list': 'admin/product/list',
    'admin/product_list': 'admin/product_list',
    'admin/update/product': 'admin/update/product',
    'admin/product/isactive': 'admin/product/isactive',
    'admin/product/company': 'admin/product/company',
    'admin/product/update': 'admin/product/update',
    'admin/product/cards/count': 'admin/product/cards/count',
    'admin/product/feture': 'admin/product/feture',
    'admin/product_picture/delete': 'admin/product_picture/delete',
    'admin/unmap/product': 'admin/unmap/product',
    // pos product
    'admin/pos_product_list': 'admin/pos_product_list',
    'admin/pos_product': 'admin/pos_product',
    'admin/pos_product/destroy': 'admin/pos_product/destroy',
    'admin/mapped_list': 'admin/mapped_list',
    'admin/product/list': 'admin/product/list',
    'admin/mapping': 'admin/mapping',
    'admin/unmapped/pos_product': 'admin/unmapped/pos_product',
    'admin/pos_product/cards/count': 'admin/pos_product/cards/count',
    //order
    'admin/orders_list': 'admin/orders_list',
    'admin/orders/status': 'admin/orders/status',
    'admin/orders/methods': 'admin/orders/methods',
    'admin/orders': 'admin/orders',
    'admin/orders/change_status': 'admin/orders/change_status',
    'admin/orders/userwise': 'admin/orders/userwise',
    //prescription
    'admin/prescription': 'admin/prescription',
    'admin/prescription/userwise': 'admin/prescription/userwise',
    'admin/prescription/status': 'admin/prescription/status',
    'admin/prescriptionlist': 'admin/prescriptionlist',
    //composition
    'admin/composition_list': 'admin/composition_list',
    'admin/compositionlist': 'admin/compositionlist',
    'admin/composition': 'admin/composition',
    'admin/composition/isactive': 'admin/composition/isactive',
    'admin/composition/validate': 'admin/composition/validate',
    'admin/composition/import': 'admin/composition/import',
    'admin/company/validate': 'admin/company/validate',
    'admin/product/validate': 'admin/product/validate',
    'admin/company/import': 'admin/company/import',
    'admin/product/import': 'admin/product/import',

    //comman api
    images: 'images',
    'admin/allCounts': 'admin/allCounts',
    'admin/salesRecord': 'admin/salesRecord',
    'admin/lastTopOrders': 'admin/lastTopOrders',
    'admin/report/sales': 'admin/report/sales',
    'admin/request_list': 'admin/request_list',
    //group
    'admin/group': 'admin/group',
    'admin/group_list': 'admin/group_list',
    'admin/menus': 'admin/menus',
    'admin/mapping/poscompany/unmapped': 'admin/mapping/poscompany/unmapped',
    'admin/pos_product/company': 'admin/pos_product/company',
    'admin/unmapped/product': 'admin/unmapped/product',
    'admin/request': 'admin/request',
    'request/status': 'request/status',
    // 'admin/notification/get': 'admin/notification/get',
    'admin/ads': 'admin/ads',
    'admin/ads/update': 'admin/ads/update',
    'admin/notification_list': 'admin/notification_list',
    'admin/ads_list': 'admin/ads_list'
};

export default CURLPath;
