// core module
import axios from 'axios';
import CURL, { headers, headers2, validateUrl } from '.';
import StoredVariables, { GetSession } from './Session';
import { Json, DECODE } from './Util';

// Authentication if availiable in eack response
export const GetAuthentication = (props, formType = '') => {
    let Auth = '',
        header = '';
    if (
        GetSession(StoredVariables.logindata) !== null &&
        GetSession(StoredVariables.logindata) !== undefined &&
        GetSession(StoredVariables.logindata) !== 'null'
    ) {
        Auth = Json(DECODE(GetSession(StoredVariables.logindata)));
    } else {
        Auth = '';
    }
    if (props && props[0]) {
        header = headers;
        if (props[2] === true) {
            header = headers2;
        }
        if (Auth !== '') {
            header.Authorization = `Bearer ${Auth.token}`;
        }
        return header;
    } else {
        return Auth;
    }
};

// Post the Data on The POST Helper API Service
export const POST = async (...props) => {
    const validate = await CURL(props[0]);
    const url = await validateUrl(validate, props);
    // console.log(url, GetAuthentication(props))
    if (validate !== false) {
        try {
            const { status, data } = await axios.post(url, props[1], { headers: GetAuthentication(props) });
            return { status, message: data.message, data: data.data };
        } catch (error) {
            return { status: 203, success: false, message: '500 (Internal Server Error)!' };
        }
    } else {
        return { status: 203, success: false, message: 'Url is not defiend! Please defiend first' };
    }
};

// Update the Data on The PUT Helper API Service
export const PUT = async (...props) => {
    const validate = await CURL(props[0]);
    const url = await validateUrl(validate, props);
    // console.log(url, GetAuthentication(props))
    if (validate !== false) {
        try {
            await delete props[1].id;
            const { status, data } = await axios.put(url, props[1], { headers: GetAuthentication(props) });
            return { status, message: data.message, data: data.data };
        } catch (error) {
            return { status: 203, success: false, message: '500 (Internal Server Error)!' };
        }
    } else {
        return { status: 203, success: false, message: 'Url is not defiend! Please defiend first' };
    }
};

// Fetch the Data on The GET Helper API Service
export const GET = async (...props) => {
    const validate = await CURL(props[0]);
    const url = await validateUrl(validate, props);
    if (validate !== false) {
        try {
            const { status, data } = await axios.get(url, { headers: GetAuthentication(props) });
            return { status, message: data.message, data: data.data };
        } catch (error) {
            return { status: 203, success: false, message: '500 (Internal Server Error)!' };
        }
    } else {
        return { status: 203, success: false, message: 'Url is not defiend! Please defiend first' };
    }
};

// Delete the Data on The DELETE Helper API Service
export const DELETE = async (...props) => {
    // console.log(props);
    const validate = await CURL(props[0]);
    // console.log(validate);
    const url = await validateUrl(validate, props);
    // console.log(url, GetAuthentication(props));
    if (validate !== false) {
        try {
            const { status, data } = await axios.delete(url, { headers: GetAuthentication(props) });
            return { status, message: data.message, data: data.data };
        } catch (error) {
            return { status: 203, success: false, message: '500 (Internal Server Error)!' };
        }
    } else {
        return { status: 203, success: false, message: 'Url is not defiend! Please defiend first' };
    }
};

// For Uploading image
// const uploadFiles = async (...props) => {
//     let formdata = new FormData();
//     formdata.append(`file`, props[1]);
//     const url = await CURL(props[0]);
//     // console.log(url, GetAuthentication(props, true))
//     if (url !== false) {
//         try {
//             const { status, data } = await axios.post(url, formdata, { headers: GetAuthentication(props, true) });
//             return { status, message: data.message, data: data.data };
//         } catch (error) {
//             return { status: 203, success: false, message: '500 (Internal Server Error)!' };
//         }
//     } else {
//         return { status: 203, success: false, message: 'Url is not defiend! Please defiend first' };
//     }
// };

// export default uploadFiles;
